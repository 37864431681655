import React, { useState } from 'react';
import { Box, Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid/Grid';
import Typography from '@material-ui/core/Typography/Typography';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import NavigationBar from '../genericComponents/NavigationBar';
import ContentBox from '../genericComponents/ContentBox';
import Footer from '../genericComponents/Footer';
import blossom from '../images/blossom.jpg';

const Reimburstment = () => {
  const [reimbustmentOpen, setReimburstmentOpen] = useState(false);

  return (
    <>
      <NavigationBar />
      <Box minHeight="calc(100vh - 86px - 171px - 42px)">
        <Container fixed>
          <Grid container spacing={3}>
            <ContentBox
              src={blossom}
              imageWidth={4}
              direction="right"
            >
              <Box>
                <Typography variant="h4" color="textPrimary" gutterBottom>
                  Tarief
                </Typography>
                <Typography color="textPrimary" paragraph>
                  Ik hanteer een standaardtarief van €50,- per consult.
                  Gemiddeld duurt een consult ca. 50 minuten.
                </Typography>
                <Typography variant="h5" color="textPrimary" gutterBottom>
                  Annulering
                </Typography>
                <Typography color="textPrimary" gutterBottom paragraph>
                  Gemaakte afspraken kunnen 24 uur voor de behandeling kosteloos worden geannuleerd.
                  Bij niet tijdige afmelding wordt het volledige tarief in rekening gebracht.
                </Typography>
                <Typography variant="h5" color="textPrimary" gutterBottom>
                  Vergoeding
                </Typography>
                <Typography color="textPrimary" paragraph>
                  Check voor een eventuele vergoeding bij je zorgverzekeraar.
                </Typography>
              </Box>
            </ContentBox>
          </Grid>
        </Container>
      </Box>
      <Footer gutterTop />
      <Dialog open={reimbustmentOpen} onClose={() => setReimburstmentOpen(false)}>
        <DialogTitle>Vergoedingen per verzekeraar</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
            Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
            natoque penatibus et magnis dis parturient montes, nascetur
            ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu,
            pretium quis, sem. Nulla consequat massa quis enim. Donec pede
            justo, fringilla vel, aliquet nec, vulputate eget, arcu. In
            enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo.
          </DialogContentText>
          <DialogActions />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Reimburstment;
